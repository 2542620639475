import { apiMethods } from '@services/methods'

import {
  baseV1URL,
  baseV2URL,
  micropd04,
  authenticationURL
} from '@configs/apis'

const urlBase = `${baseV1URL}/commerces`

const endpoints = {
  getProductsList: `${micropd04}/admin/insurances/products/list`,
  getRCVConstan: `${micropd04}/general/insurances/constants`,
  createCommerceRules: `${micropd04}/superadmin/rcv-rules/create`,
  getCommerceRules: `${micropd04}/superadmin/rcv-rules`,
  updateCommerceRule: `${micropd04}/superadmin/rcv-rules/update`,
  payInsurance: `${micropd04}/admin/insurances/pay`,

  // TODO: Check this endpoint
  uploadFile: `https://segcaracas-api-qa.pagodirecto.com/segcaracas-files-upload-qa/`,
  checkDocFiles: `https://segcaracas-api-qa.pagodirecto.com/init-textract-statemachine`
}

export const payInsurance = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.payInsurance,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const updateCommerceRule = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPutFetch({
      url: endpoints.updateCommerceRule,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getCommerceRules = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.getCommerceRules,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const createCommerceRule = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.createCommerceRules,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const uploadFile = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPutFetchInsurance({
      url: `${endpoints.uploadFile}${body.filename}`,
      body: body.file
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const checkFileData = async (body) => {
  try {
    console.log(body)
    const { ok, res, error } = await apiMethods.authPostFetchInsurance({
      url: `${endpoints.checkDocFiles}`,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getRCVProductsList = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authGetFetch(
      endpoints.getProductsList
    )
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const getRCVConstan = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authGetFetch(
      endpoints.getRCVConstan
    )
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const checkIfCommerceMobilePaymentWasAssignedToCustomerService = async (
  body
) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: endpoints.checkIfCommerceMobilePaymentWasAssignedToCustomer,
      body
    })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
