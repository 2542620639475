// ** Redux Imports
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'rcv',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAuthorizedProducts: (state, action) => {
      state.authorizedProducts = action.payload
    },
    setPickUpProducts: (state, action) => {
      state.pickUpProducts = action.payload
    },
    setBikeProducts: (state, action) => {
      state.bikeProducts = action.payload
    },
    setParticularProducts: (state, action) => {
      state.particularProducts = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload
    },
    setMaritalStatusOptions: (state, action) => {
      state.maritalStatusOptions = action.payload
    },
    setGenderOptions: (state, action) => {
      state.genderOptions = action.payload
    },
    setOccupationsOptions: (state, action) => {
      state.occupationsOptions = action.payload
    },
    setDocTypesOptions: (state, action) => {
      state.docTypesOptions = action.payload
    },
    setStatesOptions: (state, action) => {
      state.statesOptions = action.payload
    },
    setScannedDocument: (state, action) => {
      state.scannedDocument = action.payload
    },
    setScannedCarnet: (state, action) => {
      state.scannedCarnet = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setSelectedCommerceRcvRules: (state, action) => {
      state.selectedCommerceRcvRules = action.payload
    }
  }
})

export const {
  setCurrentCommerce,
  setLoading,
  setNextPageExists,
  setAuthorizedProducts,
  setPickUpProducts,
  setBikeProducts,
  setSelectedProduct,
  setParticularProducts,
  setMaritalStatusOptions,
  setGenderOptions,
  setOccupationsOptions,
  setDocTypesOptions,
  setStatesOptions,
  setCurrentStep,
  setScannedDocument,
  setScannedCarnet,
  setSelectedCommerceRcvRules
} = slice.actions

export const rcv = slice.reducer
