// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import { commerces } from './commerces'
import { withdrawalHistory } from './commerces/withdrawal-history'
import { commerceDashboard } from './commerces/dashboard'
import { wallet } from './wallet'
import { customers } from './customers'
import { reports } from './reports'
import { parkingLotTickets } from './parking-lot/tickets'
import { parkingLotBuildings } from './parking-lot/buildings'
import { workTeam } from './work-team'
import { bundles } from './tolls/bundles'
import { trips } from './tolls/trips'
import { tolls } from './tolls'
import { parkingMeters } from './parking-meters'
import { penaltyReducer } from './parking-meters/penalty'
import { reportPenaltyReducer } from './parking-meters/report'

import { bundlesAdmin } from './tolls/adminBundles'
import { usersList } from '@src/views/tolls/vehicles/store'
import { superadminArea } from '@src/views/parking-meters/areas-admin/store'
import { superadminZone } from '@src/views/parking-meters/zones-admin/store'
import { superadminSubzone } from '@src/views/parking-meters/subzone-admin/store'

import { parkingDashboard } from '@src/views/pages/parking-meters/statistics-dashboard/store'
import { plCommerces } from './parking-lot/commerces'
import { plBundles } from './parking-lot/bundles'
import { parkingLotDashboard } from './parking-lot/dashboard'
import { parkingLotInvoices } from './parking-lot/invoices'
import { superadmin } from './superadmin'

import { coupons } from './parking-lot/coupons'
import { advertising } from './users/advertising'
import { advertisingDashboard } from './users/advertising-dashboard'
import { extensions } from './users/extensions'
import { transactions } from './users/transactions'
import { mobilePaid } from './users/mobilePaid'
import { transfers } from './users/transfers'
import { approveTransactions } from './users/approve-transactions'
import { payEvent } from './users/pay-event'
import { enableRecharge } from './users/enable-recharge'
import { usersDashboard } from './users/dashboard'

import { subzones } from './parking-meters/subzones'
import { zones } from './parking-meters/zones'
import { areas } from './parking-meters/areas'
import { entities } from './parking-meters/entity'
import { towCompany } from './parking-meters/tow-company'
import { associatedCompany } from './parking-meters/associated-company'
import { ticketReducer } from './parking-meters/transactions'
import { sspos } from './sspos/sspos'
import { billingHistory } from './wallet/history'
import { billing } from './wallet/billing'
import { bankTransfers } from './wallet/bank-transfers'
import { pdAccounts } from './wallet/accounts'
import { accountPosition } from './wallet/consolidate-position'

import { transactionsInsurance } from './insurance/transactions'
import { commercesExtension } from './commerces/extension'
import { commerceDigitalAccount } from './commerces/digital-account'
import { rcv } from './commerces/rcv'

import { rechargeMethods } from './recharge-methods'
const rootReducer = {
  auth,
  commerces,
  parkingLotBuildings,
  todo,
  email,
  chat,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  wallet,
  customers,
  reports,
  workTeam,
  usersList,
  superadminArea,
  superadminZone,
  superadminSubzone,
  bundlesAdmin,
  ticketReducer,
  parkingDashboard,
  parkingLotTickets,
  plCommerces,
  plBundles,
  penaltyReducer,
  reportPenaltyReducer,
  bundles,
  trips,
  tolls,
  parkingLotInvoices,
  parkingMeters,
  parkingLotDashboard,
  superadmin,
  coupons,
  advertising,
  advertisingDashboard,
  extensions,
  enableRecharge,

  subzones,
  zones,
  areas,
  entities,
  towCompany,
  associatedCompany,
  transactions,
  mobilePaid,
  transfers,
  approveTransactions,
  payEvent,
  sspos,
  bankTransfers,
  usersDashboard,
  commerceDashboard,
  commercesExtension,

  billingHistory,
  billing,
  withdrawalHistory,
  pdAccounts,

  transactionsInsurance,
  accountPosition,
  commercesExtension,
  commerceDigitalAccount,
  rechargeMethods,
  rcv
}

export default rootReducer
