// ** Redux Imports
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'commerces',
  initialState,
  reducers: {
    setCurrentCommerce: (state, action) => {
      state.currentCommerce = action.payload
    },
    setSelectedCommerceRoles: (state, action) => {
      state.selectedCommerceRoles = action.payload
    },
    setCommerceAssociatesOptions: (state, action) => {
      state.commerceAssociatesOptions = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setCommercesFilters: (state, action) => {
      state.filters = action.payload
    },
    setFiltersCommercesAssociate: (state, action) => {
      state.filtersCommercesAssociate = action.payload
    },
    setCommercesExtensionsFilters: (state, action) => {
      state.filtersExtensions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAssociatedCommercesTransaction: (state, action) => {
      state.associatedCommercesTransaction = action.payload
    },
    setSelectedCommerce: (state, action) => {
      state.selectedCommerce = action.payload
      localStorage.setItem('selectedCommerce', JSON.stringify(action.payload))
    },
    setCommerces: (state, action) => {
      state.commerces = action.payload
    },
    setDashboardForm: (state, action) => {
      state.dashboardForm = action.payload
    },
    setDashboardLoading: (state, action) => {
      state.dashboardLoading = action.payload
    },
    setAmountsLoading: (state, action) => {
      state.amountsLoading = action.payload
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload
    },
    setAmounts: (state, action) => {
      state.amounts = action.payload
    },
    setCommercesOptions: (state, action) => {
      state.commercesOptions = action.payload
    },
    setCommercesTypesOptions: (state, action) => {
      state.commercesTypesOptions = action.payload
    },
    setSelectedCommerceInfo: (state, action) => {
      state.selectedCommerceInfo = action.payload
    },
    setData(state, action) {
      state.data = action.payload
    },
    setSelectedCommerceExtension(state, action) {
      state.selectedCommerceExtension = action.payload
    },

    setTemporalXAxis: (state, action) => {
      state.temporalXAxis = action.payload
    },
    setTemporalYAxisTotal(state, action) {
      state.temporalYAxisTotal = action.payload
    },
    setTemporalYAxisAmount(state, action) {
      state.temporalYAxisAmount = action.payload
    },
    setTotalAmount(state, action) {
      state.totalAmount = action.payload
    },
    setTotalCommercesAmount(state, action) {
      state.totalCommercesAmount = action.payload
    },
    setCommerceMobilePaid(state, action) {
      state.commerceMobilePaid = action.payload
    },
    setTotalQty(state, action) {
      state.totalQty = action.payload
    },
    setInsuranceCommerceOptions(state, action) {
      state.insuranceCommerceOptions = action.payload
    }
  }
})

export const {
  setSelectedCommerceRoles,
  setCurrentCommerce,
  setCommerceMobilePaid,
  setTotalAmount,
  setTotalCommercesAmount,
  setTotalQty,
  setTemporalXAxis,
  setTemporalYAxisTotal,
  setTemporalYAxisAmount,
  setNextPageExists,
  setCommercesFilters,
  setFiltersCommercesAssociate,
  setCommercesExtensionsFilters,
  setSelectedCommerceExtension,
  setLoading,
  setSelectedCommerce,
  setCommerces,
  setDashboardForm,
  setDashboardLoading,
  setDashboardData,
  setAmountsLoading,
  setAmounts,
  setCommercesOptions,
  setCommercesTypesOptions,
  setSelectedCommerceInfo,
  setData,
  setCommerceAssociatesOptions,
  setAssociatedCommercesTransaction,
  setInsuranceCommerceOptions
} = slice.actions

export const commerces = slice.reducer
