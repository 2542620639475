import { current } from '@reduxjs/toolkit'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export const defaultFilter = {
  pagination: {
    limit: 10,
    page: 1
  },
  sorting: {
    field: 'created_at',
    direction: 'DESC'
  },
  input: '',
  filterOptions: {
    input: '',
    category: ''
  }
}

export const defaultCommercesAssociatesFilters = {
  from_date: '',
  to_date: '',
  commerces_list: [],
  pagination: {
    limit: 10,
    page: 1
  }
}

export const defaultExtensionsFilters = {
  pagination: {
    limit: 10,
    page: 1
  },
  commerce_id: '',
  filterOptions: {
    input: ''
  }
}

const defaultValues = {
  pre_username: '',
  username: '',
  custom_name: '',
  name: '',
  short_name: '',
  email: '',
  doc_type: 'J',
  doc_id: '',
  phone: '',
  local_number: '',
  address: '',
  category: '',
  building_id: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar acceso' },
  updateFee: { action: 'updateFee', message: 'Actualizar tarifas' },
  update: { action: 'update', message: 'Actualizar acceso' },
  delete: { action: 'deleted', message: 'Eliminar acceso' }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  blocked: { label: 'Bloqueado', color: 'light-secondary' },
  inactive: { label: 'Bloqueado', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}
const initialState = {
  loading: false,
  defaultValues,
  filters: defaultFilter,
  actions: defaultActions,
  authorizedProducts: {
    PICKUP: 'PICK-UP',
    MOTOS: 'MOTOS',
    PARTICULAR: 'PARTICULAR'
  },
  pickUpProducts: [],
  bikeProducts: [],
  particularProducts: [],
  selectedProduct: null,

  maritalStatusOptions: [],
  genderOptions: [],
  occupationsOptions: [],
  docTypesOptions: [],
  statesOptions: [],
  currentStep: 1,
  scannedDocument: null,
  scannedCarnet: null,
  selectedCommerceRcvRules: null
}

export default initialState
