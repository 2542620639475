import { apiMethods } from '@services/methods'
import {
  tollsURL,
  parkingMetersURL,
  baseV2URL,
  baseV1URL,
  withdrawURL,
  micropd04
} from '@configs/apis'

export * as walletUtils from './utils'

const endpoints = {
  commercesAssociatedList: `${tollsURL}/admin/commerce/concession/toll-commerces`,
  tollWithdraw: `${tollsURL}/admin/transactions/withdraw`,
  parkingWithdraw: `${withdrawURL}/withdraw-commerce`,
  //parkingWithdraw: `${parkingMetersURL}/admin/transactions/withdraw`,
  parkingTransactionsList: `${parkingMetersURL}/admin/transactions/list`,
  parkingLotTransactionsList: `${baseV2URL}/commerces/movements`,
  // parkingLotWithdraw: `${baseV1URL}/commerces/payments/withdraw`
  parkingLotWithdraw: `${withdrawURL}/withdraw-commerce`,
  technologyWithdraw: `${micropd04}/admin/withdraw-bills/collect-children-fee`,
  getChildrenBills: `${micropd04}/admin/withdraw-bills/get-children-bills`
}

export const getChildrenBillsService = async (body) => {
  return await apiMethods.authPostFetch({
    url: endpoints.getChildrenBills,
    body
  })
}

export const commerceAudit = async (body) => {
  return await apiMethods.authPostFetch({
    url: endpoints.parkingLotWithdraw,
    body
  })
}

export const commercesAssociatedService = async () => {
  const response = await apiMethods.authGet({
    url: endpoints.commercesAssociatedList
  })

  const getAmount = (amountsArray) => {
    return amountsArray.find((amount) => amount.currency === 'BS')
  }
  const commercesAssociated = response.res.data?.toll_commerces.map(
    (commerce) => {
      return {
        ...commerce,
        funds: getAmount(commerce.amounts)
      }
    }
  )
  return commercesAssociated
}

export const parkingTransactionsListService = async (filters) => {
  try {
    const response = await apiMethods.authPost({
      url: endpoints.parkingTransactionsList,
      body: filters
    })
    return response
  } catch (error) {
    return error
  }
}

export const tollWithdrawService = async (params) =>
  await apiMethods.authPost({
    url: endpoints.tollWithdraw,
    body: params
  })

export const parkingWithdrawService = async (params) => {
  const { bank_account_id, amount, currency, commerce_id } = params

  return await apiMethods.authPost({
    url: endpoints.parkingWithdraw,
    body: { bank_account_id, amount, currency, commerce_id, is_query: false }
  })
}

export const parkingLotWithdrawService = async (params) => {
  const { bank_account_id, amount, currency, commerce_id } = params

  return await apiMethods.authPostFetch({
    url: endpoints.parkingLotWithdraw,
    body: { bank_account_id, amount, currency, commerce_id }
  })
}

export const technologyWithdrawService = async (params) => {
  return await apiMethods.authPostFetch({
    url: endpoints.technologyWithdraw
  })
}

export const parkingLotTransactionsListService = async (filters) => {
  try {
    const { ok, res: response } = await apiMethods.authPostFetch({
      url: endpoints.parkingLotTransactionsList,
      body: {
        ...filters?.filterOptions,
        skip: filters.pagination.page,
        limit: filters.pagination.limit
      }
    })

    if (!ok) return { ok, res: response }
    const res = {
      data: {
        transactions: { items: [...response.transactions] },
        amounts: [...response.amounts]
      }
    }
    return { ok, res }
  } catch (error) {
    return error
  }
}
