import {
  setAmountsLoading,
  setDashboardLoading,
  setDashboardData,
  setAmounts
} from '@store/commerces'
import { commerceAmounts, commerceDashboard } from '@services/commerces'
import { errors } from '@src/assets/constants/text'

import { formatterTransactions, getDates, getCommerceData } from '@utils'
import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import moment from 'moment'

import {
  setCommercesOptions,
  setCommercesTypesOptions,
  setData,
  setLoading,
  setSelectedCommerceInfo,
  setNextPageExists,
  setSelectedCommerceExtension,
  defaultExtensionsFilters,
  setCommercesExtensionsFilters,
  setCommerceAssociatesOptions,
  setTemporalXAxis,
  setTemporalYAxisTotal,
  setTemporalYAxisAmount,
  setTotalAmount,
  setTotalQty,
  setTotalCommercesAmount,
  setCurrentCommerce,
  setCommerceMobilePaid,
  setSelectedCommerceRoles,
  setPickUpProducts,
  setBikeProducts,
  setParticularProducts,
  setMaritalStatusOptions,
  setGenderOptions,
  setOccupationsOptions,
  setDocTypesOptions,
  setStatesOptions,
  setScannedCarnet,
  setScannedDocument,
  setCurrentStep,
  setSelectedCommerceRcvRules
} from './'
import { v4 as uuidv4 } from 'uuid'

import { t } from 'i18next'
import * as services from '../../../services/commerces/rcv'
import { setSelectedUserExtension } from '../../users/extensions/slice'
import { setSelectedUser } from '../../users/pay-event'
import { getUserByDocumentService } from '../../../services/parking-lot/tickets'
import { addCustomerWithoutPasswordService } from '../../../services/customers'

export const getRCVProducts = (id) => {
  return async (dispatch, getState) => {
    const { authorizedProducts } = getState().rcv
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await services.getRCVProductsList()

      if (!ok) throw error

      const products = res?.data?.products ?? []

      const pickUpProducts = products.filter(
        (product) => product.usage_description === authorizedProducts.PICKUP
      )
      const bikeProducts = products.filter(
        (product) => product.usage_description === authorizedProducts.MOTOS
      )
      const particularProducts = products.filter(
        (product) => product.usage_description === authorizedProducts.PARTICULAR
      )

      dispatch(setPickUpProducts(pickUpProducts))
      dispatch(setBikeProducts(bikeProducts))
      dispatch(setParticularProducts(particularProducts))
    } catch (error) {
      toast.error(error?.description ?? error.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getRCVConstan = (id) => {
  return async (dispatch, getState) => {
    const { authorizedProducts } = getState().rcv
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await services.getRCVConstan()

      const states = res?.data?.states ?? []
      const occupations = res?.data?.occupations ?? []
      const genders = res?.data?.genders ?? []
      const maritalStatus = res?.data?.marital_status ?? []
      const docTypes = res?.data?.doc_types ?? []

      const maritalStatusOptions = maritalStatus.map((status) => ({
        label: status.name,
        value: status.code
      }))

      const genderOptions = genders.map((status) => ({
        label: status.name,
        value: status.code
      }))

      const occupationsOptions = occupations.map((status) => ({
        label: status.name,
        value: status.code
      }))

      const docTypesOptions = docTypes.map((status) => ({
        label: status.name,
        value: status.code
      }))

      const statesOptions = states.map((status) => ({
        label: status.name,
        value: status.code,
        cities: status.cities.map((city) => ({
          label: city.name,
          value: city.code
        }))
      }))

      dispatch(setMaritalStatusOptions(maritalStatusOptions))
      dispatch(setGenderOptions(genderOptions))
      dispatch(setOccupationsOptions(occupationsOptions))
      dispatch(setDocTypesOptions(docTypesOptions))
      dispatch(setStatesOptions(statesOptions))

      if (!ok) throw error
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const readFileAsUint8Array = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(new Uint8Array(reader.result))
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export const checkFileData = ({ ciFilename, ccFilename }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedProduct } = getState().rcv

      const body = {
        username: '',
        contact_phone: '',
        email: '',
        titular: true,
        ci_filename: ciFilename,
        cc_filename: ccFilename,
        use_vh: selectedProduct?.usage_description
      }
      const { ok, res, error } = await services.checkFileData(body)

      if (!ok) throw error
      const { cedula, carnet_circulacion } = res?.results ?? {}

      dispatch(setScannedDocument(cedula))
      dispatch(setScannedCarnet(carnet_circulacion))
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)

      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const uploadFile = (data) => {
  return async (dispatch, getState) => {
    try {
      const docArrayBuffer = await readFileAsUint8Array(data.doc[0])
      const carnetArrayBuffer = await readFileAsUint8Array(data.carnet[0])

      const userId = 'userId' // Replace with actual userId
      const date = new Date()
      const formattedDate = date
        .toISOString()
        .replace(/[-:.]/g, '')
        .slice(0, 12) // Format: yyyyMMddhhmm
      const docExtension = data.doc[0].name.split('.').pop()
      const carnetExtension = data.carnet[0].name.split('.').pop()

      const uuidcode = uuidv4()

      const ciFilename = `citi_${userId}_${formattedDate}_${uuidcode}.jpeg`
      const ccFilename = `ccti_${userId}_${formattedDate}_${uuidcode}.jpeg`

      const { ok, res, error } = await services.uploadFile({
        filename: ciFilename,
        file: docArrayBuffer
      })

      if (!ok) throw error

      const {
        ok: okStep2,
        res: resStep2,
        error: errorStep2
      } = await services.uploadFile({
        filename: ccFilename,
        file: carnetArrayBuffer
      })

      if (!ok) throw okStep2

      dispatch(checkFileData({ ciFilename, ccFilename }))
        .then(() => {
          dispatch(setCurrentStep(3))
        })
        .catch((err) => {})
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
    } finally {
    }
  }
}

export const getUserByDocument = (document) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getUserByDocumentService(document)
      if (!ok) throw error

      return res
    } catch (error) {
      throw error
    }
  }
}

export const payRCV = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { scannedDocument, scannedCarnet, selectedProduct } = getState().rcv

      const {
        ok: okCheck,
        res: resCheck,
        error: errorCheck
      } = await getUserByDocumentService(data?.insured?.doc_id)

      let id = ''
      const username = resCheck?.data?.username

      if (!username) {
        const username = `${data?.insured?.names
          .toLowerCase()
          .replace(/ /g, '')}${data?.insured?.doc_id.slice(-4)}`

        const sendData = {
          email: data?.insured?.email,
          mobile_phone: `0${data?.insured?.phone_code}${data?.insured?.phone}`,
          username,
          doc: `${data?.insured?.doc_type}${data?.insured?.doc_id}`
        }

        const {
          ok: okNew,
          res: resNew,
          error: errorNew
        } = await addCustomerWithoutPasswordService(sendData)

        id = resNew?.user_id
      } else {
        id = resCheck?.data?._id
      }

      const body = {
        ...data,
        doc_id_url_image: scannedDocument?.url,
        driver_license_url_image: scannedCarnet?.url,
        product: selectedProduct,
        pd_user: id
      }

      const { ok, res, error } = await services.payInsurance(body)

      if (!ok) throw error

      toast.success(res?.data?.message ?? 'Pago realizado con éxito')
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const createCommerceConfiguration = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces

      const body = {
        ...data,
        commerceAssociatedId: selectedCommerceInfo?._id
      }

      const { ok, res, error } = await services.createCommerceRule(body)

      if (!ok) throw error
      toast.success(res?.message ?? 'Configuración creada con éxito')
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerceConfiguration = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceRcvRules } = getState().rcv
      const { selectedCommerceInfo } = getState().commerces

      const body = {
        ...data,
        commerceAssociatedId: selectedCommerceInfo?._id,
        id: selectedCommerceRcvRules?._id
      }

      const { ok, res, error } = await services.updateCommerceRule(body)
      if (!ok) throw error

      toast.success(res?.message ?? 'Configuración actualizada con éxito')
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommerceRcvConfiguration = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceInfo } = getState().commerces

      const body = {
        commerceId: selectedCommerceInfo?._id
      }

      const { ok, res, error } = await services.getCommerceRules(body)
      if (!ok) throw error

      dispatch(setSelectedCommerceRcvRules(res?.data?.response ?? null))
    } catch (error) {
      toast.error(error?.description ?? error?.message ?? errors?.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}
