import { lazy } from 'react'

const Wallet = lazy(() => import('@views/commerces/wallet'))

const WorkTeam = lazy(() => import('@views/commerces/work-team'))
const WorkTeamCommerce = lazy(() =>
  import('@views/commerces/work-team-commerce')
)
const Commerces = lazy(() => import('@views/commerces/commerces-list'))
const Support = lazy(() => import('@views/commerces/support'))
const Logout = lazy(() => import('@views/commerces/logout'))
const Dashboard = lazy(() => import('@views/commerces/dashboard'))
const TechnologyDashboard = lazy(() =>
  import('@views/commerces/technology-dashboard')
)
const AccessControl = lazy(() => import('@views/commerces/access-control'))
const Transactions = lazy(() => import('@views/commerces/transactions'))
const Extensions = lazy(() => import('@views/commerces/extensions'))
const Reports = lazy(() => import('@views/commerces/reports'))
const WithdrawalHistory = lazy(() =>
  import('@views/commerces/withdrawal-history')
)
const DashboardAdmin = lazy(() =>
  import('@views/commerces/admin-commerce-dashboard')
)

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const AddExtension = lazy(() => import('@views/commerces/add-extension'))
const PayExtension = lazy(() => import('@views/commerces/pay-extension'))
const AddExtensionAccs = lazy(() => import('@views/commerces/add-extension-accs'))
const DigitalAccount = lazy(() => import('@views/commerces/digital-account'))

const Accounts = lazy(() => import('@views/commerces/accounts'))
const MassiveTransfer = lazy(() => import('@views/commerces/massive-transfer'))

const MobilePayment = lazy(() => import('@views/commerces/notify/mobile-payment'))
const BankTransferPayment = lazy(() => import('@views/commerces/notify/bank-transfer'))
const RCV = lazy(() => import('@views/commerces/rcv'))

const CommercesRoutes = [
  {
    element: <RCV />,
    path: `/commerces/rcv`,
    meta: {
      actions: ['level-2'],
      resources: ['Common']
    }
  },
  {
    element: <BankTransferPayment />,
    path: `/commerces/notify/bank-transfer`,
    meta: {
      actions: ['level-2'],
      resources: ['Common']
    }
  },
  {
    element: <MobilePayment />,
    path: `/commerces/notify/mobile-payment`,
    meta: {
      actions: ['level-2'],
      resources: ['Common']
    }
  },
  {
    element: <Accounts />,
    path: `/commerces/accounts`,
    meta: {
      actions: ['pagodirecto-level-2', 'level-2'],
      resources: ['Common']
    }
  },
  {
    element: <MassiveTransfer />,
    path: `/commerces/massive-transfer`,
    meta: {
      actions: ['pagodirecto-level-2', 'level-2'],
      resources: ['Common']
    }
  },
  {
    element: <TechnologyDashboard />,
    path: `/commerces/technology-dashboard`,
    meta: {
      actions: ['tecnologia-level-2'],
      resources: ['Common']
    }
  },

  // TODO: Change to the correct acl
  {
    element: <PayExtension />,
    path: `/commerces/pay-extension`,
    meta: {
      actions: ['pagodirecto-level-2', 'level-0'],
      resources: ['Common']
    }
  },
  {
    element: <AddExtension />,
    path: `/commerces/add-extension`,
    meta: {
      actions: ['pagodirecto-level-2', 'level-1'],
      resources: ['Common']
    }
  },
  {
    element: <AddExtensionAccs />,
    path: `/commerces/add-extension-accs`,
    meta: {
      actions: [
        'pagodirecto-level-2',
        'extension-level-1',
        'extension-level-2'
      ],
      resources: ['Common']
    }
  },
  {
    element: <AddExtension />,
    path: `/commerces/add-extension`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE, ability.SALES, 'level-1'],
      resources: ['Common']
    }
  },
  {
    element: <AddExtensionAccs />,
    path: `/commerces/add-extension-accs`,
    meta: {
      actions: ['pagodirecto-level-2', 'extension-level-1', 'extension-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <WithdrawalHistory />,
    path: `/commerces/withdrawal-history`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE, ability.SALES, 'level-1'],
      resources: ['Common']
    }
  },
  {
    element: <DashboardAdmin />,
    path: `/commerces/admin-dashboard`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resources: ['Common']
    }
  },
  // !TODO: chequear el ability
  {
    element: <Extensions />,
    path: `/commerces/commerces-extensions`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.PROMOTER,
        'extension-level-3'
      ],
      resources: ['Common']
    }
  },
  {
    element: <Transactions />,
    path: `/commerces/commerces-transactions`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.SATC,
        ability.ATC,
        'level-2'
      ],
      resources: ['Common']
    }
  },
  {
    element: <DigitalAccount />,
    path: `/commerces/digital-account`,
    meta: {
      actions: ['pagodirecto-level-2', 'level-2'],
      resources: ['Common']
    }
  },
  {
    element: <Reports />,
    path: `/commerces/commerces-reports`,
    meta: {
      actions: [ability.MANAGER],
      resources: ['Common']
    }
  },
  {
    path: '/commerces/list',
    element: <Commerces />,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    path: '/commerces/dashboard',
    element: <Dashboard />,
    meta: { actions: ['level-1'], resources: ['Common'] }
  },
  {
    path: '/commerces/wallet',
    element: <Wallet />,
    meta: {
      actions: ['level-0'],
      resources: ['Common']
    }
  },
  {
    element: <WorkTeam />,
    path: '/commerces/work-team',
    meta: {
      actions: [
        'level-1',
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.SATC
      ],
      resources: ['Common']
    }
  },
  {
    element: <WorkTeamCommerce />,
    path: '/superadmin/commerces/work-team',
    meta: { actions: ['pagodirecto-level-2'], resources: ['Common'] }
  },
  {
    element: <Support />,
    path: '/commerces/support',
    meta: { actions: ['level-1'], resources: ['Common'] }
  },
  {
    element: <Logout />,
    path: 'commerces/logout',
    meta: { actions: ['level-0'], resources: ['Common'] }
  },
  {
    element: <AccessControl />,
    path: '/commerces/access-control',
    meta: { actions: ['level-0'], resources: ['Common'] }
  }
]

export default CommercesRoutes
