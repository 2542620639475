// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const workTeamSlice = createSlice({
  name: 'workTeam',
  initialState,
  reducers: {
    clearState: (state) => {
      state.customerOptions = []
      state.customerSelected = {}
      state.customOperator = {}
    },

    setPagination: (state, action) => {
      state.pagination = action.payload
    },

    setCustomOperator: (state, action) => {
      state.customOperator = action.payload
    },
    setCustomerOptions: (state, action) => {
      state.customerOptions = action.payload
    },
    setCustomerSelected: (state, action) => {
      state.customerSelected = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setUsers: (state, action) => {
      state.data = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setOperator: (state, action) => {
      state.selectedOperator = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setFinerInfo: (state, action) => {
      state.finerInfo = action.payload
    },
    setUpdatedOperator: (state, action) => {
      state.updatedOperator = action.payload
    },

    setViewsOptions: (state, action) => {
      state.viewsOptions = action.payload
    }
  }
})

export const {
  setUsers,
  setRoles,
  setOperator,
  setFilters,
  setNextPageExists,
  setLoading,
  setFinerInfo,
  setCustomerOptions,
  setUpdatedOperator,
  setCustomerSelected,
  clearState,
  setCustomOperator,
  setPagination,
  setViewsOptions
} = workTeamSlice.actions

export const workTeam = workTeamSlice.reducer
