import { apiMethods } from '@services/methods'
import {
  couponsURL,
  baseV2URL,
  micropd03,
  baseV1URL,
  authenticationURL
} from '@configs/apis'

const endpoints = {
  updateService: `${baseV1URL}/constants/support-info`,
  getAll: `${authenticationURL}/superadmin/view/all`,
  assignViews: `${authenticationURL}/superadmin/view/assign`
}

export const updateSupportConstantsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateService,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getAllViewsService = async () => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getAll)
    return response
  } catch (error) {
    return error
  }
}

export const assignViewsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.assignViews,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
